import { FormHelperText } from '@mui/material';

const FormError = ({ children }) => {
  try {
    const data = JSON.parse(children);
    if (!!data) {
      return <FormHelperText sx={{ color: 'red' }}>{data.defaultMessage}</FormHelperText>;
    }
  } catch {
    // Couldn't parse as JSON; do nothing
  }
  return <FormHelperText sx={{ color: 'red' }}>{children}</FormHelperText>;
};

export default FormError;
