/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Autocomplete,
  CircularProgress
} from '@mui/material';

// icon

import { Eye } from 'react-feather';

//

import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from 'lodash/debounce';
import { useToast } from '@context/toastContext';

import { getErrorMessage } from '@api/handleApiError';

// modal

// constant
// component
import UILoader from '@components/UILoader';
import { getAllClinicsAPI, searchPurchasedPackagesAPI } from '@api/main';
import ViewModal from './ViewModal';
import { DateRangePicker } from '@mui/lab';

const rowsPerPageOptions = [10, 20, 50];

const PurchasedPackages = () => {
  const showToast = useToast();
  const [open, setOpen] = useState(false);
  const [purchasedPackages, setPurchasedPackages] = useState();
  const [totalRows, setTotalRows] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [openViewModal, setOpenViewModal] = useState(false);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [fromPrice, setFromPrice] = useState('');
  const [toPrice, setToPrice] = useState('');
  const [clinicId, setClinicId] = useState({ label: 'Tất Cả', value: 0 });
  const [date, setDate] = useState([moment().startOf('month').valueOf(), new Date().valueOf()]);
  const [searchFilter, setSearchFilter] = useState({
    keyword,
    fromDate: date[0],
    toDate: date[1],
    fromPrice,
    toPrice,
    clinicId: clinicId?.value
  });
  const [options, setOptions] = useState();
  const loading = open && options.length === 1;

  const handleSearch = () => {
    if (toPrice < fromPrice) {
      setToPrice(fromPrice * 1 + 300000);
    }
    setSearchFilter({
      keyword,
      fromDate: date[0],
      toDate: date[1],
      fromPrice,
      toPrice,
      clinicId: clinicId?.value
    });
    setPage(0);
  };
  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };
  const handleFromPriceChange = (event) => {
    setFromPrice(event.target.value);
    setToPrice(event.target.value * 1 + 300000);
  };
  const handleToPriceChange = (event) => {
    setToPrice(event.target.value);
  };
  const handleClick = (pack) => {
    setSelectedPackage(pack);
  };

  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleClear = () => {
    setSearchFilter({
      keyword: '',
      fromDate: moment().startOf('month').valueOf(),
      toDate: new Date().valueOf(),
      fromPrice: null,
      toPrice: null,
      clinicId: ''
    });
    setClinicId({ label: 'Tất Cả', value: 0 });
    setKeyword('');
    setDate([moment().startOf('month').valueOf(), new Date().valueOf()]);
    setFromPrice('');
    setToPrice('');
    setPage(0);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setRowPerPage(event.target.value);
    setRefreshToggle(!refreshToggle);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchData(rowPerPage, page + 1, searchFilter);
  };

  const fetchData = useCallback(
    debounce(async (rowPerPage, page, searchFilter) => {
      try {
        setIsLoading(true);
        const result = await searchPurchasedPackagesAPI(rowPerPage, page, searchFilter);
        setPurchasedPackages(result.data.pageData);
        setTotalRows(result.data.paging.totalItem);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );
  useEffect(() => {
    fetchData(rowPerPage, page + 1, searchFilter);
  }, [refreshToggle, searchFilter]);

  const handleSearchInputChange = (value) => {
    setDate([moment(value[0]).startOf('day').valueOf(), moment(value[1]).endOf('day').valueOf()]);
  };

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        try {
          const res = await getAllClinicsAPI();
          const optionList = res.map((i) => {
            return { label: i.clinicName, value: i.clinicId };
          });
          setOptions([...options, ...optionList]);
        } catch (error) {
          showToast({
            type: 'error',
            message: getErrorMessage(error)
          });
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([{ label: 'Tất Cả', value: 0 }]);
    }
  }, [open]);
  return (
    <>
      <Helmet>
        <title>Purchased Packages | Dr.Cloud Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <UILoader open={isLoading} />
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  flex: 1
                }}
              >
                Gói Đã Bán
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                      <DateRangePicker
                        startText="Ngày"
                        endText="Ngày"
                        value={date}
                        onChange={handleSearchInputChange}
                        inputFormat="dd/MM/yyyy"
                        renderInput={({ inputProps, ...startProps }, endProps) => {
                          const startValue = inputProps.value;
                          delete inputProps.value;
                          return (
                            <TextField
                              fullWidth
                              {...startProps}
                              inputProps={inputProps}
                              value={`${startValue} - ${endProps.inputProps.value}`}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Autocomplete
                        fullWidth
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        value={clinicId}
                        onChange={(e, newValue) => {
                          setClinicId(newValue);
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.value}>
                              {option.label}
                            </li>
                          );
                        }}
                        noOptionsText="Không có phòng khám "
                        isOptionEqualToValue={(option) => option.value === 0}
                        getOptionLabel={(option) => option.label}
                        options={options || []}
                        loading={loading}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Phòng Khám"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        onChange={handleKeywordChange}
                        value={keyword}
                        label="Tên Gói"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12} />
                    <Grid item md={3} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Khoảng Tiền Từ"
                          variant="outlined"
                          onChange={handleFromPriceChange}
                          value={fromPrice}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">VND</InputAdornment>
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          error={toPrice * 1 < fromPrice * 1}
                          type="number"
                          helperText={toPrice * 1 < fromPrice * 1 ? `Giá trị này phải lớn hơn ${fromPrice}` : false}
                          fullWidth
                          label="Đến"
                          variant="outlined"
                          value={toPrice}
                          onChange={handleToPriceChange}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">VND</InputAdornment>
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          py: 1
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mx: 1, height: '40px' }}
                          onClick={handleSearch}
                        >
                          Tìm kiếm
                        </Button>
                        <Button sx={{ height: '40px' }} color="primary" variant="outlined" onClick={handleClear}>
                          Xóa
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Tên Gói</TableCell>
                        <TableCell>Mã Gói</TableCell>
                        <TableCell>Bệnh Nhân</TableCell>
                        <TableCell>Ngày Thanh Toán</TableCell>
                        <TableCell>Ngày Hết Hạn</TableCell>
                        <TableCell>Giá Tiền</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {purchasedPackages?.map((i) => (
                        <TableRow hover key={i.orderPackageItemId}>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '200px' }} noWrap>
                              {i.packageName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '100px' }} noWrap>
                              {i.packageCode}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '150px' }} noWrap>
                              {i.clinicPatient.fullName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '100px' }}>
                              {moment(i.paymentDateUnix).format('dd/MM/yyyy')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '100px' }}>
                              {moment(i.expiredDateUnix).format('dd/MM/yyyy')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '100px' }}>
                              {Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(i.totalAmount)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                handleOpenViewModal(i);
                                handleClick(i);
                              }}
                            >
                              <Eye width={16} height={16} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                onPageChange={handlePageChange}
                page={page}
                count={totalRows}
                rowsPerPage={rowPerPage}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </Card>
          </Box>
          {openViewModal && (
            <ViewModal open={openViewModal} purchasedPackage={selectedPackage} close={handleCloseViewModal} />
          )}
        </Container>
      </Box>
    </>
  );
};

export default PurchasedPackages;
