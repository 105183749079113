/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback, Fragment } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Popover,
  Avatar,
  InputAdornment,
  Autocomplete,
  CircularProgress
} from '@mui/material';

// mui icon

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import BlockIcon from '@mui/icons-material/Block';

//

import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from 'lodash/debounce';
import { useToast } from '@context/toastContext';

import { getErrorMessage } from '@api/handleApiError';

// modal

// constant
import { packageStatus, packageStatusConfig, packageStatusEnum, packageTypeConfig } from '@utils/constants';
// component
import UILoader from '@components/UILoader';
import { getAllClinicsAPI, getCoverUrlAPI, searchPackagesAPI } from '@api/main';
import PackageEditModal from './PackageEditModal';
import ConfirmModal from './ConfirmModal';

const UserMoreActions = ({
  click,
  pack,
  selectedPack,
  handleOpenEditModal,
  handleOpenViewModal,
  handleOpenConfirmModal,
  handleOpenCancelModal
}) => {
  const [open, setOpen] = useState(false);

  const isWaitForApprove = () => {
    if (selectedPack?.packageStatusId === packageStatusEnum.WaitForApprove) {
      return true;
    }
    return false;
  };
  const isApproved = () => {
    if (selectedPack?.packageStatusId === packageStatusEnum.Approved) {
      return true;
    }
    return false;
  };
  const isInvalid = () => {
    if (selectedPack?.packageStatusId === packageStatusEnum.Invalid) {
      return true;
    }
    return false;
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen(e.currentTarget);
          click(pack);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            width: 'auto',
            overflow: 'inherit'
          }
        }}
      >
        <MenuItem
          disabled={!isWaitForApprove()}
          onClick={() => {
            handleClose();
            handleOpenEditModal(pack);
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<EditIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Chỉnh Sửa
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenViewModal(pack);
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<ViewComfyIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Xem Chi Tiết
            </Typography>
          </Button>
        </MenuItem>
        {isWaitForApprove() && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenConfirmModal(pack);
              }}
            >
              <Button sx={{ p: 0 }} startIcon={<DoneSharpIcon />}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Chấp Nhận
                </Typography>
              </Button>
            </MenuItem>
          </>
        )}
        {isInvalid() && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenCancelModal(pack);
              }}
            >
              <Button sx={{ p: 0 }} startIcon={<BlockIcon />}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Ngưng Gói
                </Typography>
              </Button>
            </MenuItem>
          </>
        )}
        {isApproved() && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenCancelModal(pack);
              }}
            >
              <Button sx={{ p: 0 }} startIcon={<BlockIcon />}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Ngưng Gói
                </Typography>
              </Button>
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
};

const rowsPerPageOptions = [10, 20, 50];

const Packages = () => {
  const showToast = useToast();
  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState();
  const [totalRows, setTotalRows] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [packageTypeId, setPackageTypeId] = useState(0);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditAble, setIsEditAble] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isAcepted, setIsAcepted] = useState(false);
  const [packageStatusId, setPackageStatusId] = useState(0);
  const [fromPrice, setFromPrice] = useState('');
  const [toPrice, setToPrice] = useState('');
  const [clinicId, setClinicId] = useState({ label: 'Tất Cả', value: 0 });
  const statusId = packageStatusId === 0 ? null : packageStatusId;
  const typeId = packageTypeId === 0 ? null : packageTypeId;
  const [searchFilter, setSearchFilter] = useState({
    keyword,
    packageTypeId: typeId,
    packageStatusId: statusId,
    fromPrice,
    toPrice,
    clinicId: clinicId?.value
  });
  const [options, setOptions] = useState();
  const loading = open && options.length === 1;

  const handleSearch = () => {
    const statusId = packageStatusId === 0 ? null : packageStatusId;
    const typeId = packageTypeId === 0 ? null : packageTypeId;
    if (toPrice < fromPrice) {
      setToPrice(fromPrice * 1 + 300000);
    }
    setSearchFilter({
      keyword,
      packageTypeId: typeId,
      packageStatusId: statusId,
      fromPrice,
      toPrice,
      clinicId: clinicId?.value
    });
    setPage(0);
  };
  const handleTypeChange = (event) => {
    setPackageTypeId(event.target.value);
  };
  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };
  const handleFromPriceChange = (event) => {
    setFromPrice(event.target.value);
    setToPrice(event.target.value * 1 + 300000);
  };
  const handleToPriceChange = (event) => {
    setToPrice(event.target.value);
  };
  const handleStatusChange = (e) => {
    setPackageStatusId(e.target.value);
  };
  const handleClick = (pack) => {
    setSelectedPackage(pack);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
    setIsEditAble(true);
  };
  const handleCloseEditModal = (modalResult) => {
    setOpenEditModal(false);
    if (modalResult === 'SAVED') {
      setRefreshToggle(!refreshToggle);
    }
  };
  const handleOpenViewModal = () => {
    setOpenEditModal(true);
    setIsEditAble(false);
  };
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
    setIsAcepted(true);
  };
  const handleCloseConfirmModal = (modalResult) => {
    setOpenConfirmModal(false);
    if (modalResult === 'SAVED') {
      setRefreshToggle(!refreshToggle);
    }
  };
  const handleOpenCancelModal = () => {
    setOpenConfirmModal(true);
    setIsAcepted(false);
  };
  const handleClear = () => {
    setSearchFilter({
      keyword: '',
      packageTypeId: null,
      packageStatusId: null,
      fromPrice: null,
      toPrice: null,
      clinicId: ''
    });
    setClinicId({ label: 'Tất Cả', value: 0 });
    setPackageTypeId(0);
    setPackageStatusId(0);
    setKeyword('');
    setFromPrice('');
    setToPrice('');
    setPage(0);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setRowPerPage(event.target.value);
    setRefreshToggle(!refreshToggle);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchData(rowPerPage, page + 1, searchFilter);
  };

  const fetchData = useCallback(
    debounce(async (rowPerPage, page, searchFilter) => {
      try {
        setIsLoading(true);
        const result = await searchPackagesAPI(rowPerPage, page, searchFilter);
        setPackages(result.data.pageData);
        setTotalRows(result.data.paging.totalItem);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );
  useEffect(() => {
    fetchData(rowPerPage, page + 1, searchFilter);
  }, [refreshToggle, searchFilter]);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        try {
          const res = await getAllClinicsAPI();
          const optionList = res.map((i) => {
            return { label: i.clinicName, value: i.clinicId };
          });
          setOptions([...options, ...optionList]);
        } catch (error) {
          showToast({
            type: 'error',
            message: getErrorMessage(error)
          });
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([{ label: 'Tất Cả', value: 0 }]);
    }
  }, [open]);

  return (
    <>
      <Helmet>
        <title>Packages | Dr.Cloud Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <UILoader open={isLoading} />
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography variant="h2" sx={{ flex: 1 }}>
                Gói Dịch Vụ
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Loại Gói"
                        onChange={handleTypeChange}
                        select
                        value={packageTypeId}
                        name="status"
                        variant="outlined"
                      >
                        <MenuItem value={0}>Tất Cả</MenuItem>
                        <MenuItem value={1}>Gói Khám</MenuItem>
                        <MenuItem value={2}>Gói Tiêm Chủng</MenuItem>
                        <MenuItem value={3}>Gói Xét Nghiệm</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Autocomplete
                        fullWidth
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        value={clinicId}
                        onChange={(e, newValue) => {
                          setClinicId(newValue);
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.value}>
                              {option.label}
                            </li>
                          );
                        }}
                        noOptionsText="Không có phòng khám "
                        isOptionEqualToValue={(option) => option.value === 0}
                        getOptionLabel={(option) => option.label}
                        options={options || []}
                        loading={loading}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Phòng Khám"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        onChange={handleStatusChange}
                        value={packageStatusId}
                        select
                        fullWidth
                        label="Trạng Thái"
                        variant="outlined"
                      >
                        <MenuItem value={0}>Tất Cả</MenuItem>
                        {packageStatus.map((i, index) => (
                          <MenuItem key={index} value={i.value}>
                            {i.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12} />
                    <Grid item md={3} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Khoảng Tiền Từ"
                          variant="outlined"
                          onChange={handleFromPriceChange}
                          value={fromPrice}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">VND</InputAdornment>
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          error={toPrice * 1 < fromPrice * 1}
                          type="number"
                          helperText={toPrice * 1 < fromPrice * 1 ? `Giá trị này phải lớn hơn ${fromPrice}` : false}
                          fullWidth
                          label="Đến"
                          variant="outlined"
                          value={toPrice}
                          onChange={handleToPriceChange}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">VND</InputAdornment>
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        onChange={handleKeywordChange}
                        value={keyword}
                        label="Tên Gói"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          py: 1
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mx: 1, height: '40px' }}
                          onClick={handleSearch}
                          startIcon={<SearchIcon />}
                        >
                          Tìm kiếm
                        </Button>
                        <Button sx={{ height: '40px' }} color="primary" variant="outlined" onClick={handleClear}>
                          Xóa
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Tên Gói</TableCell>
                        <TableCell>Mã Gói</TableCell>
                        <TableCell>Tên Phòng Khám</TableCell>
                        <TableCell>Loại Gói</TableCell>
                        <TableCell>Trạng Thái</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {packages?.map((i) => (
                        <TableRow hover key={i.packageCode}>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Avatar alt="Picture" sx={{ width: 192, height: 82 }} src={i.cover?.fileUrl} variant="rounded" />
                              <Box sx={{ ml: 2, mt: 2.5, width: '250px', overflow: 'hidden' }}>
                                <Typography variant="body2" sx={{ width: '250px' }} noWrap>
                                  {i.packageName}
                                </Typography>
                                <Box sx={{ display: 'flex' }}>
                                  {i.promotionPrice >= 0 ? (
                                    <Typography variant="body2" sx={{ textDecorationLine: 'line-through' }}>
                                      {Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
                                        i.price
                                      )}
                                    </Typography>
                                  ) : (
                                    <Typography variant="body2">
                                      {Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
                                        i.price
                                      )}
                                    </Typography>
                                  )}
                                  <Typography sx={{ ml: 2 }} variant="body2" color="secondary">
                                    {i.promotionPrice >= 0 &&
                                      Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
                                        i.promotionPrice
                                      )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '200px' }} noWrap>
                              {i.packageCode}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" noWrap>
                              {i.clinicName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{packageTypeConfig[i.packageTypeId]?.title}</Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={packageStatusConfig[i.packageStatusId]?.title}
                              color={packageStatusConfig[i.packageStatusId]?.color}
                              sx={{ fontWeight: 'bold', height: '25px', borderRadius: '6px' }}
                            />
                          </TableCell>
                          <TableCell>
                            <UserMoreActions
                              click={handleClick}
                              selectedPack={selectedPackage}
                              pack={i}
                              handleOpenEditModal={handleOpenEditModal}
                              handleOpenViewModal={handleOpenViewModal}
                              handleOpenConfirmModal={handleOpenConfirmModal}
                              handleOpenCancelModal={handleOpenCancelModal}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                onPageChange={handlePageChange}
                page={page}
                count={totalRows}
                rowsPerPage={rowPerPage}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </Card>
          </Box>
          {openEditModal && (
            <PackageEditModal
              open={openEditModal}
              pack={selectedPackage}
              close={handleCloseEditModal}
              isEditable={isEditAble}
            />
          )}
          {openConfirmModal && (
            <ConfirmModal
              open={openConfirmModal}
              pack={selectedPackage}
              close={handleCloseConfirmModal}
              isAcepted={isAcepted}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Packages;
