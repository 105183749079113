import { useEffect, useState } from 'react';
import { useToast } from '@context/toastContext';
import moment from 'moment';

// mui
import {
  Box,
  DialogTitle,
  Typography,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Chip,
  Drawer,
  Container
} from '@mui/material';
// icon
import { X } from 'react-feather';
// components

import { getErrorMessage } from '@api/handleApiError';

// constants

// api
import { getPurchasedPackageDetail } from '@api/main';

const ViewModal = ({ open, purchasedPackage, close }) => {
  const showToast = useToast();
  const [services, setServices] = useState();
  const [detail, setDetail] = useState();

  useEffect(async () => {
    try {
      if (purchasedPackage) {
        const result = await getPurchasedPackageDetail(purchasedPackage.orderPackageItemId);
        setDetail(result.data);
        setServices(result.data.services);
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, []);
  return (
    <Drawer anchor="right" open={open} onClose={close}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          justifyContent: 'space-between',
          backgroundColor: '#F4F6F8'
        }}
      >
        <DialogTitle sx={{ p: 0 }}>
          <Typography
            noWrap
            sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '25px', fontWeight: 'bold' }}
          >
            {detail?.patientName}
          </Typography>
        </DialogTitle>
        <IconButton onClick={close}>
          <X />
        </IconButton>
      </Box>
      <Container sx={{ width: '500px' }}>
        {detail && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="caption" sx={{ color: '#B9B9C3' }}>
                Tên Gói:
              </Typography>
              <Box>
                <Typography variant="body1" sx={{ width: '450px' }}>
                  {detail?.packageName}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="caption" sx={{ color: '#B9B9C3' }}>
                Tên Phòng Khám:
              </Typography>
              <Box>
                <Typography variant="body1" sx={{ width: '450px' }}>
                  {detail?.clinicName}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography variant="caption" sx={{ color: '#B9B9C3' }}>
                Mã Gói:
              </Typography>
              <Box>
                <Typography variant="body1">{detail?.packageCode}</Typography>
              </Box>
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography variant="caption" sx={{ color: '#B9B9C3' }}>
                Giá Tiền:
              </Typography>
              <Box>
                <Typography variant="body1">
                  {Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(detail?.totalAmount)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography variant="caption" sx={{ color: '#B9B9C3' }}>
                Ngày Thanh Toán:
              </Typography>
              <Box>
                <Typography variant="body1">{moment(detail?.paymentDateUnix).format('dd/MM/yyyy')}</Typography>
              </Box>
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography variant="caption" sx={{ color: '#B9B9C3' }}>
                Ngày Hết Hạn:
              </Typography>
              <Box>
                <Typography variant="body1">{moment(detail?.expiredDateUnix).format('dd/MM/yyyy')}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={{ borderTop: '1px solid #E0E0E0' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Dịch Vụ</TableCell>
                <TableCell>Giá</TableCell>
                <TableCell>Lượt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services?.map((service) => (
                <TableRow key={service.serviceId}>
                  <TableCell>
                    <Typography variant="body2" sx={{ width: '200px' }} noWrap>
                      {service.serviceName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {service?.servicePrice >= 0 ? (
                      Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(service?.servicePrice)
                    ) : (
                      <Typography variant="body2">0 đ</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="success"
                      size="small"
                      label={
                        <Typography variant="caption">{service.usedCount + '/' + service.originalCount}</Typography>
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Container>
    </Drawer>
  );
};
export default ViewModal;
