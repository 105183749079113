import { useEffect } from 'react';

// form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Card,
  CardContent,
  FormControl,
  Input
} from '@mui/material';

import { useToast } from '@context/toastContext';
import FormError from '@components/FormError/FormError';
import { getErrorMessage } from '@api/handleApiError';
import { updateModeratorAPI } from '@api/main';

export default function ChangePasswordModal({ open, user, close }) {
  const showToast = useToast();
  const EditUserModalSchema = yup.object().shape({
    newPassword: yup.string().required().min(8),
    passwordConfirm: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword')], 'Mật khẩu phải khớp nhau ')
  });

  const defaultValues = {
    newPassword: ''
  };
  useEffect(() => {
    reset(defaultValues);
  }, []);
  const { handleSubmit, register, reset, errors } = useForm({
    mode: 'all',
    resolver: yupResolver(EditUserModalSchema),
    defaultValues
  });

  const onSubmit = async (data) => {
    try {
      await updateModeratorAPI(user.userId, data);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Đổi mật khẩu thành công !!'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ backgroundColor: '#F4F6F8' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography sx={{ fontSize: '20px' }}>Đổi Mật Khẩu</Typography>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Card sx={{ border: 'none' }}>
              <CardContent>
                <Box sx={{ pb: 1 }}>
                  <FormControl disabled fullWidth sx={{ mb: 3 }}>
                    <Input label="Email" value={user.userName} variant="outlined" />
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Mật Khẩu"
                    margin="normal"
                    name="newPassword"
                    type="password"
                    inputRef={register()}
                    variant="outlined"
                    error={!!errors.newPassword}
                  />
                  {errors && errors.newPassword && <FormError>{errors.newPassword.message}</FormError>}
                  <TextField
                    fullWidth
                    label="Nhập lại Mật Khẩu"
                    margin="normal"
                    name="passwordConfirm"
                    type="password"
                    inputRef={register()}
                    variant="outlined"
                    error={!!errors.passwordConfirm}
                  />
                  {errors && errors.passwordConfirm && <FormError>{errors.passwordConfirm.message}</FormError>}
                </Box>
                <Box>
                  <Button
                    type="submit"
                    sx={{ display: 'flex', mr: 'auto', ml: 'auto', mt: 1, textDecoration: 'none' }}
                    color="primary"
                    variant="contained"
                  >
                    <Typography>Đổi Mật Khẩu</Typography>
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </DialogContent>
        </form>
      </Box>
    </Dialog>
  );
}
