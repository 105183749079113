import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, Typography, Divider } from '@mui/material';
import {
  BarChart as BarChartIcon,
  LogOut as LogOutIcon,
  Activity as ActivityIcon,
  Users as UsersIcon,
  Package as PackageIcon,
  DollarSign,
  CreditCard,
  ShoppingBag
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { logoutAC } from '@store/actions/auth';
import NavItem from './NavItem';
import { UserTypeEnum } from '@utils/constants';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  { href: '/app/clinics', icon: ActivityIcon, title: 'Phòng khám' },
  { href: '/app/packages', icon: PackageIcon, title: 'Gói' },
  { href: '/app/purchased-packages', icon: ShoppingBag, title: 'Gói Đã Bán' },
  { href: '/app/orders', icon: DollarSign, title: 'Đơn Hàng' },
  { href: '/app/transactions', icon: CreditCard, title: 'Giao Dịch' },
  {
    href: '/app/staffs',
    icon: UsersIcon,
    title: 'Nhân Viên'
  }
];

const DashboardSidebar = ({ name, onMobileClose, openMobile }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Hidden lgUp>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            p: 2
          }}
        >
          <Typography color="textPrimary" variant="h4">
            {name}
          </Typography>
        </Box>
        <Divider />
      </Hidden>
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <Box key={item.title}>
              {(user.userTypeId === UserTypeEnum.SuperAdmin || item.title !== 'Nhân Viên') && (
                <NavItem href={item.href} title={item.title} icon={item.icon} />
              )}
            </Box>
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <NavItem href="#" key="logout" title="Đăng Xuất" icon={LogOutIcon} onClick={() => dispatch(logoutAC())} />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
