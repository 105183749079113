/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import axios, { handleRefreshToken } from './base';

// *AUTHENTICATION API
/**
 * Login
 * @param {{
  userName: string;
  password: string;
}} data
*/
export const loginAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, { auth: false });
};

export const forgotPasswordAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, data, { auth: false });
};

export const resetPasswordAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, data, { auth: false });
};

export const resendConfirmationAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/resend-confirmation`, data, { auth: false });
};

export const getUserInfoAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
};

export const updateUserAPI = async (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/me`, data);
};

export const updatePasswordAPI = async (data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/change-password`, data);
};

export const healthCheckAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/health-check`);
};

export const refreshTokenAPI = async () => {
  return handleRefreshToken();
};

/**
 * Get merchant statistics
 * @param {{
 *
}} data
*/

export const getClinicsAPI = async (pageSize = 50, pageNumber = 1, data) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinics/management/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data
  );
};

export const getAllClinicsAPI = async (pageNumber = 1) => {
  let arr = [];
  const apiRes = await getClinicsAPI(100, pageNumber, { keyword: '', clinicStatusId: null });
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData);
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await getAllClinicsAPI(pageNumber + 1);
      arr = [...arr, ...nextPageRes];
    }
  }
  return arr;
};

export const getCLinicDetailAPI = async (clinicId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/management`);
};

export const updateClinicDetailAPI = async (clinicId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/management`, data);
};

export const updateClinicStatusAPI = async (clinicId, data = false) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/management/set-status`, data);
};

export const cloneClinicAPI = async (clinicId) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}/clone`);
}

export const deleteClinicAPI = async (clinicId) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/clinics/${clinicId}`);
}

export const createModeratorAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/moderator`, data);
};
export const getModeratorAPI = async (pageSize = 50, pageNumber = 1, keyword = '') => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/moderator?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`
  );
};
export const updateModeratorAPI = async (userId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}/moderator/change-password`, data);
};
export const deleteModeratorAPI = async (userId) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}/moderator`);
};

export const getDashboardDataAPI = async (fromDate = 0, toDate = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard?fromDate=${fromDate}&toDate=${toDate}`);
};

export const getClinicInfoAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/info`);
};
export const searchPackagesAPI = async (pageSize, pageNumber, filter) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/admin/packages/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter
  );
};
export const getPackageDetailsAPI = async (packageId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/packages/${packageId}`);
};
export const approvePackageAPI = async (packageId) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/admin/packages/${packageId}/approve`);
};
export const disablePackageAPI = async (packageId) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/admin/packages/${packageId}/disable`);
};
export const updatePackageAPI = async (packageId, data) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/admin/packages/${packageId}`, data);
};
export const createPhysicalFileAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files/request-upload-image`, data);
};
export const putUploadImageAPI = async (preSignedURL, data) => {
  return axios.put(`${preSignedURL}`, data, {
    __auth: false,
    upload: data.type
  });
};
export const putUploadDoneAPI = async (fileId) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/files/${fileId}/upload-done`);
};
export const getCoverUrlAPI = async (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files/get-urls`, data);
};

export const searchOrdersAPI = async (pageSize, pageNumber, filter) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/admin/orders/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter
  );
};

export const searchTransactionsAPI = async (pageSize, pageNumber, filter) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/admin/transactions/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter
  );
};

export const searchPurchasedPackagesAPI = async (pageSize, pageNumber, filter) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/admin/orders/packages/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter
  );
};

export const getPurchasedPackageDetail = async (orderPackageItemId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/admin/orders/packages/${orderPackageItemId}/details`);
};
