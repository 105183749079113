import { useEffect } from 'react';

// form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Card,
  CardContent
} from '@mui/material';

import FormError from '@components/FormError/FormError';
import { getErrorMessage } from '@api/handleApiError';
import { createModeratorAPI } from '@api/main';
import { useToast } from '@context/toastContext';

export default function AddStaffModal({ open, close }) {
  const showToast = useToast();

  const EditUserModalSchema = yup.object().shape({
    userName: yup.string().email().required().trim(),
    firstName: yup.string().required().trim(),
    lastName: yup.string().required().trim(),
    password: yup.string().required().min(8),
    passwordConfirm: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], 'Mật khẩu phải khớp nhau ')
  });

  const defaultValues = {
    userName: '',
    firstName: '',
    lastName: '',
    password: ''
  };
  useEffect(() => {
    reset(defaultValues);
  }, []);
  const { handleSubmit, register, reset, errors } = useForm({
    mode: 'all',
    resolver: yupResolver(EditUserModalSchema),
    defaultValues
  });

  const onSubmit = async (data) => {
    try {
      await createModeratorAPI(data);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Tạo Tài Khoản Thành Công!'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ backgroundColor: '#F4F6F8' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography sx={{ fontSize: '20px' }}>Thêm Nhân Viên</Typography>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Card>
              <CardContent>
                <Box sx={{ pb: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '48%' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        label="Họ và tên đệm"
                        margin="normal"
                        name="lastName"
                        inputRef={register()}
                        variant="outlined"
                        error={!!errors.lastName}
                      />
                      {errors && errors.lastName && <FormError>{errors.lastName.message}</FormError>}
                    </Box>
                    <Box sx={{ width: '48%' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        label="Tên"
                        margin="normal"
                        name="firstName"
                        inputRef={register()}
                        variant="outlined"
                        error={!!errors.firstName}
                      />
                      {errors && errors.firstName && <FormError>{errors.firstName.message}</FormError>}
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    type="email"
                    name="userName"
                    inputRef={register()}
                    variant="outlined"
                    error={!!errors.userName}
                  />
                  {errors && errors.userName && <FormError>{errors.userName.message}</FormError>}

                  <TextField
                    fullWidth
                    label="Mật Khẩu"
                    margin="normal"
                    name="password"
                    type="password"
                    inputRef={register()}
                    variant="outlined"
                    error={!!errors.password}
                  />
                  {errors && errors.password && <FormError>{errors.password.message}</FormError>}

                  <TextField
                    fullWidth
                    label="Nhập lại Mật Khẩu"
                    margin="normal"
                    name="passwordConfirm"
                    type="password"
                    inputRef={register()}
                    variant="outlined"
                    error={!!errors.passwordConfirm}
                  />
                  {errors && errors.passwordConfirm && <FormError>{errors.passwordConfirm.message}</FormError>}
                </Box>
                <Box>
                  <Button
                    type="submit"
                    sx={{ display: 'flex', mr: 'auto', ml: 'auto', mt: 1 }}
                    color="primary"
                    variant="contained"
                  >
                    <Typography>Tạo</Typography>
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </DialogContent>
        </form>
      </Box>
    </Dialog>
  );
}
