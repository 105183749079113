import { Backdrop, CircularProgress } from '@mui/material';

const UILoader = ({ open }) => {
  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: 999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default UILoader;
