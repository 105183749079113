import React, { useEffect, useState } from 'react';

import { useToast } from '@context/toastContext';

import { Avatar, Box, Button, styled, Typography } from '@mui/material';
import { createPhysicalFileAPI, putUploadDoneAPI, putUploadImageAPI } from '@api/main';
import { getErrorMessage } from '@api/handleApiError';
import UILoader from '@components/UILoader';
import { Edit } from 'react-feather';

const UploadStyle = styled('label')(() => ({
  '.image-upload': {
    position: 'relative'
  },
  '.image-upload:after': {
    opacity: 0,
    transition: 'all 0.5s',
    WebkitTransition: 'all 0.5s',
    width: '100%',
    height: '100%',
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    background: 'rgba(44, 44, 44, 0.6)',
    content: '""',
    cursor: 'pointer'
  },
  '.image-upload:before': {
    opacity: 0,
    transition: 'all 0.5s',
    WebkitTransition: 'all 0.5s'
  },
  '.overlay-icon': {
    opacity: 0,
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.5s',
    WebkitTransition: 'all 0.5s',
    cursor: 'pointer'
  },
  '.image-upload:hover': {
    '.overlay-icon': {
      opacity: 1
    }
  },
  'image-upload:hover:after': {
    opacity: 1
  },
  'image-upload:hover:before': {
    opacity: 1
  }
}));

const UploadPicture = ({ physicalFileType, image, setCoverId, isEditable }) => {
  const showToast = useToast();
  const [file, setFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [physicalFile, setPhysicalFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!image) {
      setPreviewFile(image);
    }
  }, [image]);

  const onUploadChange = async (e) => {
    if (!!e.target.files[0]) {
      setFile(e.target.files[0]);
      const res = await createPhysicalFileAPI({
        fileName: e.target.files[0].name,
        fileLength: e.target.files[0].size,
        physicalFileType
      });
      setPhysicalFile(res.data);

      const reader = new FileReader();
      const files = e.target.files[0];
      reader.onload = () => {
        setPreviewFile(reader.result);
      };
      if (files) {
        reader.readAsDataURL(files);
      }
    }
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      await putUploadImageAPI(physicalFile.presignedUploadUrl, file);
      await putUploadDoneAPI(physicalFile.physicalFileId);
      setCoverId(physicalFile.physicalFileId);
      showToast({
        type: 'success',
        message: 'Thành Công'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setIsLoading(false);
      setFile();
    }
  };

  const handleRemove = () => {
    setCoverId(null);
    setPreviewFile('');
    setFile();
    setPhysicalFile();
  };

  return (
    <Box>
      <UILoader open={isLoading} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <UploadStyle htmlFor="raised-button-file">
          <Box className="image-upload">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              onChange={onUploadChange}
              type={!isEditable ? 'file' : ''}
            />
            <Avatar
              component="span"
              alt="Picture"
              sx={{ width: 163, height: 70 }}
              src={previewFile}
              variant="rounded"
            />
            <Edit style={{ display: isEditable && 'none' }} className="overlay-icon" />
          </Box>
        </UploadStyle>
        <Box sx={{ ml: 1, mt: 2, display: 'inline-block' }}>
          <Button onClick={handleUpload} disabled={isEditable || !file} variant="contained" sx={{ mx: 2 }}>
            Upload
          </Button>
          <Button onClick={handleRemove} disabled={isEditable} sx={{ mr: 3 }} variant="outlined">
            Reset
          </Button>
          <Typography sx={{ ml: 2, mt: 1 }} variant="caption">
            Allowed JPG, GIF or PNG. Max size of 2MB
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadPicture;
