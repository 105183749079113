import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useToast } from '@context/toastContext';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// mui
import {
  Dialog,
  Box,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  InputAdornment,
  Input,
  Tabs,
  Tab,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  DialogContent,
  TextField
} from '@mui/material';

// components
import { getErrorMessage } from '@api/handleApiError';
import PropTypes from 'prop-types';
// constants
import { packageTypeConfig, PhysicalFileTypeEnum } from '@utils/constants';

// api
import { getPackageDetailsAPI, updatePackageAPI } from '@api/main';
import FormError from '@components/FormError/FormError';
import UploadPicture from '@components/UploadPicture';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: '600px' }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const PackageEditModal = ({ open, pack, close, isEditable }) => {
  const showToast = useToast();
  const [value, setValue] = useState(0);
  const [services, setServices] = useState();

  const EditClinicSchema = yup.object().shape({
    packageName: yup.string().required().trim(),
    description: yup.string().trim()
  });
  const defaultValues = {
    packageId: '',
    packageCode: '',
    packageName: '',
    packageType: '',
    originPrice: '',
    promotionPrice: '',
    description: '',
    expireAfter: '',
    coverFileId: ''
  };
  const { register, handleSubmit, reset, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(EditClinicSchema),
    defaultValues
  });
  const [file, setFile] = useState('');

  useEffect(async () => {
    try {
      if (pack) {
        const result = await getPackageDetailsAPI(pack.packageId);
        setFile(pack.cover?.fileUrl);
        reset({
          ...defaultValues,
          ...{
            packageId: result.data.packageId,
            packageCode: result.data.packageCode,
            packageName: result.data.packageName,
            packageType: packageTypeConfig[result.data.packageTypeId]?.title,
            price: result.data.price,
            promotionPrice: result.data.promotionPrice,
            description: result.data.description,
            expiredAfterDays: result.data.expiredAfterDays,
            coverFileId: result.data.coverFileId,
            packageStatusId: result.data.packageStatusId,
            clinicName: result.data.clinicName,
            clinicId: result.data.clinicId
          }
        });
        setServices(result.data.services);
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, [reset]);

  const onSubmit = async (data) => {
    delete data.packageCode;
    delete data.packageType;
    delete data.price;
    delete data.promotionPrice;
    try {
      await updatePackageAPI(pack.packageId, data);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Cập nhật thông tin gói thành công !'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog component={'div'} open={open} onClose={close} fullWidth maxWidth="sm" sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          justifyContent: 'space-between',
          backgroundColor: '#F4F6F8'
        }}
      >
        <DialogTitle sx={{ p: 0 }}>
          <Typography
            noWrap
            sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '25px', fontWeight: 'bold' }}
          >
            Gói Dịch Vụ
          </Typography>
        </DialogTitle>
      </Box>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: '170px' }}
        >
          <Tab label="Thông Tin" sx={{ whiteSpace: 'noWrap' }} {...a11yProps(0)} />
          <Tab label="Dịch Vụ" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="coverFileId"
              control={control}
              render={({ onChange }) => (
                <UploadPicture
                  physicalFileType={PhysicalFileTypeEnum.Avatar}
                  isEditable={!isEditable}
                  image={file}
                  setCoverId={onChange}
                />
              )}
            />
            <Box sx={{ mt: 1, lineHeight: 'auto' }}>
              <Box>
                <Box sx={{ height: '70px' }}>
                  <Typography variant="caption">Tên Gói:</Typography>
                  <Box>
                    <Input
                      disabled={!isEditable}
                      error={!!errors.packageName}
                      sx={{ width: '100%' }}
                      name="packageName"
                      inputRef={register()}
                    />
                    {errors && errors.packageName && <FormError>{errors.packageName.message}</FormError>}
                  </Box>
                </Box>
                <Box sx={{ height: '70px' }}>
                  <Typography variant="caption">Mã Gói:</Typography>
                  <Box>
                    <Input disabled sx={{ width: '100%' }} name="packageCode" inputRef={register()} />
                  </Box>
                </Box>
                <Box sx={{ height: '70px' }}>
                  <Typography variant="caption">Loại Gói:</Typography>
                  <Box>
                    <Input disabled sx={{ width: '100%' }} name="packageType" inputRef={register()} />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '48%' }}>
                    <Typography variant="caption">Giá:</Typography>
                    <Box sx={{ width: '100%' }}>
                      <Input
                        disabled
                        sx={{ width: '100%' }}
                        name="price"
                        inputRef={register()}
                        variant="outlined"
                        inputprops={{
                          endAdornment: <InputAdornment position="end">VND</InputAdornment>
                        }}
                        error={!!errors.originPrice}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: '48%', height: '75px' }}>
                    <Typography variant="caption">Giá Khuyến Mãi:</Typography>
                    <Box sx={{ width: '100%' }}>
                      <Input
                        disabled
                        sx={{ width: '100%' }}
                        name="promotionPrice"
                        inputRef={register()}
                        variant="outlined"
                        inputprops={{
                          endAdornment: <InputAdornment position="end">VND</InputAdornment>
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="caption">Mô Tả:</Typography>
                  <Box>
                    <TextField
                      disabled={!isEditable}
                      sx={{ width: '100%' }}
                      multiline
                      name="description"
                      maxRows={3}
                      inputRef={register()}
                    />
                    {errors && errors.description && <FormError>{errors.description.message}</FormError>}
                  </Box>
                </Box>
                <Box>
                  <Typography variant="caption">Gói hết hạn sau (ngày)</Typography>
                  <Box>
                    <Input disabled sx={{ width: '100%' }} name="expiredAfterDays" inputRef={register()} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ height: '540px', width: '434px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dịch Vụ</TableCell>
                  <TableCell>Giá</TableCell>
                  <TableCell>Lượt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services?.map((service) => (
                  <TableRow key={service.serviceId}>
                    <TableCell sx={{ width: '250px' }}>{service.serviceName}</TableCell>
                    <TableCell>{service.servicePrice}</TableCell>
                    <TableCell>{service.serviceCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TabPanel>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0,
          justifyContent: 'space-between',
          backgroundColor: '#F4F6F8'
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <DialogActions>
            {isEditable ? (
              <>
                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  color="success"
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Lưu
                </Button>
                <Button onClick={close} color="error" variant="contained" sx={{ mr: 3 }}>
                  Đóng
                </Button>
              </>
            ) : (
              <Button onClick={close} color="error" variant="contained" sx={{ mr: 3 }}>
                Đóng
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
export default PackageEditModal;
