/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  IconButton,
  Popover
} from '@mui/material';

// mui icon
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PasswordIcon from '@mui/icons-material/Password';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from 'lodash/debounce';

import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';

// modal
import AddStaffModal from './AddStaffModal';
import ChangePasswordModal from './ChangePasswordModal';
import StaffDeleteModal from './StaffDeleteModal';
import { getModeratorAPI } from '@api/main';

// components
import UILoader from '@components/UILoader';

const UserMoreActions = ({ user, handleOpenChangeModal, handleOpenDeleteModal, click }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen(e.currentTarget);
          click(user);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            width: 'auto',
            overflow: 'inherit'
          }
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenChangeModal();
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<PasswordIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Đổi Mật Khẩu
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteModal(user);
            handleClose();
          }}
        >
          <Button sx={{ p: 0 }} color="error" startIcon={<PersonRemoveOutlinedIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Xóa Tài Khoản
            </Typography>
          </Button>
        </MenuItem>
      </Popover>
    </>
  );
};

const rowsPerPageOptions = [10, 20, 50];

const Staff = () => {
  const showToast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [staffs, setStaffs] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openChangePwModal, setOpenChangePwModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const fetchData = useCallback(
    debounce(async (pageSize, page, keyword) => {
      try {
        setIsLoading(true);
        const result = await getModeratorAPI(pageSize, page, keyword);
        setStaffs(result.data.pageData);
        setTotalRows(result.data.paging.totalItem);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );

  useEffect(() => {
    fetchData(rowPerPage, page + 1, keyword);
  }, [refreshToggle, rowPerPage, searchInput]);

  const handleClick = (user) => {
    setSelectedStaff(user);
  };
  const handleOpenChangeModal = () => {
    setOpenChangePwModal(true);
  };

  const handleCloseChangeModal = () => {
    setOpenChangePwModal(false);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handleCloseAddModal = (modalResult) => {
    setOpenAddModal(false);
    if (modalResult === 'SAVED') {
      setRefreshToggle(!refreshToggle);
    }
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = (modalResult) => {
    setOpenDeleteModal(false);
    if (modalResult === 'SAVED') {
      setRefreshToggle(!refreshToggle);
    }
  };
  const handleLimitChange = (event) => {
    setPage(0);
    setRowPerPage(event.target.value);
    setRefreshToggle(!refreshToggle);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchData(rowPerPage, page + 1, searchInput);
  };

  const handleSearch = () => {
    setSearchInput(keyword);
  };
  const handleClear = () => {
    setKeyword('');
    setPage(0);
    setRefreshToggle(!refreshToggle);
  };

  return (
    <>
      <Helmet>
        <title>Staffs | Dr.Cloud Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <UILoader open={isLoading} />
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  flex: 1
                }}
              >
                Nhân Viên
              </Typography>
              <CircularProgress size="1.25rem" sx={{ alignSelf: 'center', display: 'none' }} />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      label="Tên Nhân Viên"
                      name="merchantOrderId"
                      onChange={handleKeywordChange}
                      value={keyword}
                      variant="outlined"
                      sx={{ width: '20%' }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        py: 1
                      }}
                    >
                      <Button
                        color="primary"
                        onClick={handleSearch}
                        variant="contained"
                        sx={{ mx: 2 }}
                        startIcon={<SearchIcon />}
                      >
                        Tìm kiếm
                      </Button>
                      <Button onClick={handleClear} color="primary" variant="outlined">
                        Xóa
                      </Button>
                    </Box>
                    <Box sx={{ ml: 'auto', py: 1, display: 'flex' }}>
                      <Button
                        color="success"
                        startIcon={<PersonAddIcon />}
                        variant="contained"
                        onClick={handleOpenAddModal}
                      >
                        Thêm
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Họ Và Tên</TableCell>
                        <TableCell>Mã Nhân Viên</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Thời Gian Tạo Tài Khoản</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {staffs?.map((staff) => (
                        <TableRow key={staff.userId} hover>
                          <TableCell>
                            <Typography variant="body2">{staff.lastName + ' ' + staff.firstName}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '150px' }} noWrap>
                              {staff.userId}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{staff.userName}</Typography>
                          </TableCell>
                          <TableCell>{format(staff.createdAtUnix, 'dd/MM/yyyy')}</TableCell>
                          <TableCell>
                            <UserMoreActions
                              click={handleClick}
                              user={staff}
                              handleOpenDeleteModal={handleOpenDeleteModal}
                              handleOpenChangeModal={handleOpenChangeModal}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                onPageChange={handlePageChange}
                page={page}
                count={totalRows}
                rowsPerPage={rowPerPage}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </Card>
          </Box>
          {openAddModal && <AddStaffModal open={openAddModal} close={handleCloseAddModal} />}
          {openChangePwModal && (
            <ChangePasswordModal open={openChangePwModal} close={handleCloseChangeModal} user={selectedStaff} />
          )}
          {openDeleteModal && (
            <StaffDeleteModal open={openDeleteModal} close={handleCloseDeleteModal} user={selectedStaff} />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Staff;
