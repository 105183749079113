import { Navigate } from 'react-router-dom';
import DashboardLayout from '@components/DashboardLayout';
import MainLayout from '@components/MainLayout';
import Dashboard from '@views/Dashboard';
import Login from '@views/Login';
import NotFound from '@views/NotFound';
import ForgotPassword from '@views/ForgotPassword';
import ResetPassword from '@views/ResetPassword';
import Staffs from '@views/staffs';
import Clinics from '@views/clinics';
import Packages from '@views/packages';
import Orders from '@views/orders';
import Transactions from '@views/transactions';
import PurchasedPackage from '@views/purchased-packages';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'staffs', element: <Staffs /> },
      { path: 'clinics', element: <Clinics /> },
      { path: 'packages', element: <Packages /> },
      { path: 'purchased-packages', element: <PurchasedPackage /> },
      { path: 'orders', element: <Orders /> },
      { path: 'transactions', element: <Transactions /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
