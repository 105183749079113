import React from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useToast } from '@context/toastContext';
import { approvePackageAPI, disablePackageAPI } from '@api/main';
import { getErrorMessage } from '@api/handleApiError';

export default function ConfirmModal({ open, pack, close, isAcepted }) {
  const showToast = useToast();

  const onApprove = async () => {
    try {
      await approvePackageAPI(pack.packageId);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Gói Đã Được Chấp Nhận !!'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };
  const onDisable = async () => {
    try {
      await disablePackageAPI(pack.packageId);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Gói Đã Được Hủy !!'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };
  return (
    <Dialog open={open} onClose={close}>
      <Box sx={{ backgroundColor: '#F4F6F8' }}>
        <DialogTitle>
          {isAcepted ? (
            <Typography sx={{ textAlign: 'center', fontSize: '17px' }}>
              {'Gói ' + pack.packageName + ' sẽ được chấp nhận'}
            </Typography>
          ) : (
            <Typography sx={{ textAlign: 'center', fontSize: '17px' }}>
              {'Gói ' + pack.packageName + ' sẽ không được chấp nhận'}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            {isAcepted ? (
              <Button color="success" onClick={onApprove} variant="contained">
                Chấp nhận
              </Button>
            ) : (
              <Button color="error" onClick={onDisable} variant="contained">
                Hủy Gói
              </Button>
            )}

            <Button color="primary" variant="contained" onClick={close}>
              Đóng
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
