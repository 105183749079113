/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Popover
} from '@mui/material';

// mui icon

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';

//

import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from 'lodash/debounce';
import { format } from 'date-fns';

import { useToast } from '@context/toastContext';
import { getClinicsAPI, cloneClinicAPI, deleteClinicAPI } from '@api/main';
import { getErrorMessage } from '@api/handleApiError';

// modal
import ClinicEditModals from './ClinicEditModals';

// constant
import { statusConfig, ClinicPlanTypeConfig } from '@utils/constants';
// component
import UILoader from '@components/UILoader';

const UserMoreActions = ({ clinic, handleOpenEditModal, handleOpenDetailModal, handleClone, handleDelete, click }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen(e.currentTarget);
          click(clinic);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            width: 'auto',
            overflow: 'inherit'
          }
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenEditModal(clinic);
            handleClose();
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<EditIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Chỉnh Sửa
            </Typography>
          </Button>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDetailModal(clinic);
            handleClose();
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<ViewComfyIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Chi Tiết
            </Typography>
          </Button>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClone(clinic);
            handleClose();
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<ContentCopyIcon />}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Clone
            </Typography>
          </Button>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleDelete(clinic);
            handleClose();
          }}
        >
          <Button sx={{ p: 0 }} startIcon={<DeleteIcon />} color="error">
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              DELETE
            </Typography>
          </Button>
        </MenuItem>
      </Popover>
    </>
  );
};

const _defaultFilter = {
  status: 0
};

const rowsPerPageOptions = [10, 20, 50];

const Clinics = () => {
  const [clinicStatusId, setClinicStatusId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterValues, setFilterValues] = useState(_defaultFilter);
  const showToast = useToast();
  const [clinics, setClinics] = useState();
  const [totalRows, setTotalRows] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [selectedClinic, setSelectedClinic] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isEditAble, setIsEditAble] = useState(false);
  const [searchFilter, setSearchFilter] = useState({ keyword, clinicStatusId });

  const handleStatusChange = (event) => {
    setFilterValues({
      ...filterValues,
      [event.target.name]: event.target.value
    });
    setClinicStatusId(event.target.value);
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const fetchData = useCallback(
    debounce(async (pageSize, page, { keyword, clinicStatusId }) => {
      try {
        setIsLoading(true);
        const statusId = clinicStatusId === 0 ? null : clinicStatusId;
        const result = await getClinicsAPI(pageSize, page, { keyword, clinicStatusId: statusId });
        setClinics(result.data.pageData);
        setTotalRows(result.data.paging.totalItem);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );

  useEffect(() => {
    fetchData(rowPerPage, page + 1, { keyword, clinicStatusId });
  }, [refreshToggle, searchFilter]);

  const handleClick = (clinic) => {
    setSelectedClinic(clinic);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
    setIsEditAble(true);
  };
  const handleCloseEditModal = (modalResult) => {
    setOpenEditModal(false);
    if (modalResult === 'SAVED') {
      setRefreshToggle(!refreshToggle);
    }
  };
  const handleOpenDetailModal = () => {
    setOpenEditModal(true);
    setIsEditAble(false);
  };

  const handleSearch = () => {
    setSearchFilter({ keyword, clinicStatusId });
  };
  const handleClear = () => {
    setKeyword('');
    setPage(0);
    setFilterValues(_defaultFilter);
    setClinicStatusId(null);
    setRefreshToggle(!refreshToggle);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setRowPerPage(event.target.value);
    setRefreshToggle(!refreshToggle);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchData(rowPerPage, page + 1, { keyword, clinicStatusId });
  };

  const handleCloneClinic = async (clinic) => {
    try {
      setIsLoading(true);
      await cloneClinicAPI(clinic.clinicId);
      setIsLoading(false);
      setRefreshToggle(!refreshToggle);
    } catch (error) {
      setIsLoading(false);
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  const handleDeleteClinic = async (clinic) => {
    try {
      setIsLoading(true);
      await deleteClinicAPI(clinic.clinicId);
      setIsLoading(false);
      setRefreshToggle(!refreshToggle);
    } catch (error) {
      setIsLoading(false);
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Clincs | Dr.Cloud Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <UILoader open={isLoading} />
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  flex: 1
                }}
              >
                Phòng Khám
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Trạng Thái"
                        name="status"
                        select
                        onChange={handleStatusChange}
                        value={filterValues.status}
                        variant="outlined"
                      >
                        <MenuItem value={0}>Tất Cả</MenuItem>
                        <MenuItem value={1}>Hoạt Động</MenuItem>
                        <MenuItem value={2}>Ngưng Hoạt Động</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label="Tên Phòng Khám"
                        onChange={handleKeywordChange}
                        value={keyword}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          py: 1
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mx: 1 }}
                          onClick={handleSearch}
                          startIcon={<SearchIcon />}
                        >
                          Tìm kiếm
                        </Button>
                        <Button color="primary" variant="outlined" onClick={handleClear}>
                          Xóa
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Tên Phòng Khám</TableCell>
                        <TableCell>Mã Khách Hàng</TableCell>
                        <TableCell>Hợp Đồng</TableCell>
                        <TableCell>Trạng Thái</TableCell>
                        <TableCell>Loại Gói</TableCell>
                        <TableCell>Thời Gian Hết Hạn</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clinics?.map((clinic) => (
                        <TableRow hover key={clinic.clinicId}>
                          <TableCell>
                            <Typography variant="body2">{clinic.clinicName}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '200px' }} noWrap>
                              {clinic.customerCode}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{clinic.contractInfo}</Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={statusConfig[clinic.clinicStatusId]?.label}
                              color={statusConfig[clinic.clinicStatusId]?.color}
                              sx={{ fontWeight: 'bold', borderRadius: '6px' }}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {ClinicPlanTypeConfig[clinic.planTypeId]?.title || 'Không xác định'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {format(new Date(clinic.expiredAtDateTimeUnix), 'dd/MM/yyyy')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <UserMoreActions
                              click={handleClick}
                              clinic={clinic}
                              handleOpenEditModal={handleOpenEditModal}
                              handleOpenDetailModal={handleOpenDetailModal}
                              handleClone={handleCloneClinic}
                              handleDelete={handleDeleteClinic}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                onPageChange={handlePageChange}
                page={page}
                count={totalRows}
                rowsPerPage={rowPerPage}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </Card>
          </Box>
          {openEditModal && (
            <ClinicEditModals
              open={openEditModal}
              clinic={selectedClinic}
              close={handleCloseEditModal}
              isEditable={isEditAble}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Clinics;
