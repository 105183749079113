export const TransactionStatusEnum = {
  WaitForPayment: 1000,
  PaymentProcessing: 3000,
  Success: 5000,
  Canceled: 9000,
  Error: 9999
};

export const TransactionStatusStyle = {
  1000: {
    label: 'Wait for payment',
    color: 'warning'
  },
  3000: {
    label: 'Payment processing',
    color: 'warning'
  },
  5000: {
    label: 'Success',
    color: 'success'
  },
  9000: {
    label: 'Canceled',
    color: 'warning'
  },
  9999: {
    label: 'Error',
    color: 'error'
  }
};

export const ClinicStatusEnum = {
  Active: 1,
  Deactivated: 2,
  Deleted: 99
};
export const statusConfig = {
  1: { label: 'Hoạt Động', color: 'success', isActive: true },
  2: { label: 'Ngưng Hoạt Động', color: 'error', isActive: false },
  99: { label: 'Đã Xóa', color: 'error', isActive: false }
};

export const packageTypeEnum = {
  ExaminationPack: 1,
  InjectionPack: 2,
  TestPack: 3
};
export const packageTypeConfig = {
  [packageTypeEnum.ExaminationPack]: { title: 'Gói Khám' },
  [packageTypeEnum.InjectionPack]: { title: 'Gói Tiêm Chủng' },
  [packageTypeEnum.TestPack]: { title: 'Gói Xét Nghiệm' }
};
export const packageStatusEnum = {
  Draft: 500,
  WaitForApprove: 1000,
  Approved: 2000,
  Disabled: 9000,
  Invalid: 10000
};
export const packageStatus = [
  { title: 'Nháp', value: 500 },
  { title: 'Chờ Xác Nhận', value: 1000 },
  { title: 'Đã Xác Nhận', value: 2000 },
  { title: 'Ngừng Hoạt Động', value: 9000 },
  { title: 'Không Hợp Lệ', value: 10000 }
];
export const packageStatusConfig = {
  [packageStatusEnum.Draft]: { title: 'Nháp', color: 'success' },
  [packageStatusEnum.WaitForApprove]: { title: 'Chờ Xác Nhận', color: 'default' },
  [packageStatusEnum.Approved]: { title: 'Đã Xác Nhận', color: 'secondary' },
  [packageStatusEnum.Disabled]: { title: 'Ngừng Hoạt Động', color: 'error' },
  [packageStatusEnum.Invalid]: { title: 'Không Hợp Lệ', color: 'warning' }
};

export const PhysicalFileTypeEnum = {
  Avatar: 1
};
export const OrderStatus = [
  { title: 'Chưa Thanh Toán', value: 1000 },
  { title: 'Hoàn Thành', value: 5000 },
  { title: 'Đã Hủy', value: 9999 },
  { title: 'Lỗi', value: 10000 }
];

export const OrderStatusEnum = {
  WaitForPayment: 1000,
  Success: 5000,
  Canceled: 9999,
  Error: 10000
};

export const OrderStatusConfig = {
  [OrderStatusEnum.WaitForPayment]: { title: 'Chưa Thanh Toán', color: 'default' },
  [OrderStatusEnum.Success]: { title: 'Hoàn Thành', color: 'success' },
  [OrderStatusEnum.Canceled]: { title: 'Đã Hủy', color: 'warning' },
  [OrderStatusEnum.Error]: { title: 'Lỗi', color: 'warning' }
};

export const TransactionsStatus = [
  { title: 'Hoàn Thành', value: 1 },
  { title: 'Chưa Hoàn Thành', value: 0 }
];

export const UserTypeEnum = {
  SuperAdmin: 0,
  Patient: 1,
  Clinic: 2,
  Moderator: 3
};

export const ClinicPlanTypeEnum = {
  Trial: 1,
  Basic: 2,
  Full: 3,
  Advanced: 4,
  Vip: 5
};

export const ClinicPlanTypeConfig = {
  [ClinicPlanTypeEnum.Trial]: { title: 'Gói dùng thử' },
  [ClinicPlanTypeEnum.Basic]: { title: 'Gói cơ bản' },
  [ClinicPlanTypeEnum.Full]: { title: 'Gói đầy đủ' },
  [ClinicPlanTypeEnum.Advanced]: { title: 'Gói nâng cao' },
  [ClinicPlanTypeEnum.Vip]: { title: 'Gói Vip' }
};
