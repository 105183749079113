import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: JSON.stringify({
      defaultMessage: 'Trường bắt buộc nhập',
      values: { path: '${path}' }
    }),
    notType: JSON.stringify({
      defaultMessage: 'Trường này phải là số',
      values: { path: '${path}' }
    })
  },
  string: {
    default: JSON.stringify({
      defaultMessage: 'Giá trị không hợp lệ',
      values: { path: '${path}' }
    }),
    email: JSON.stringify({
      defaultMessage: 'Email phải hợp lệ ',
      values: { path: '${path}' }
    }),
    min: JSON.stringify({
      defaultMessage: 'Mật khẩu phải có 8 kí tự trở lên',
      values: { path: '${path}' }
    })
  },
  number: {
    default: JSON.stringify({
      defaultMessage: 'Giá trị không hợp lệ',
      values: { path: '${path}' }
    }),
    min: JSON.stringify({
      defaultMessage: 'Trường này phải lớn hơn hoặc bằng {min}',
      values: { path: '${path}', min: '${min}' }
    }),
    max: JSON.stringify({
      defaultMessage: 'Trường này phải nhỏ hơn hoặc bằng {max}',
      values: { path: '${path}', max: '${max}' }
    }),
    integer: JSON.stringify({
      defaultMessage: 'Giá trị không hợp lệ',
      values: { path: '${path}' }
    }),
    lessThan: JSON.stringify({
      defaultMessage: 'Trường này phải nhỏ hơn {less}',
      values: { path: '${path}', less: '${less}' }
    }),
    moreThan: JSON.stringify({
      defaultMessage: 'Trường này phải luôn luôn lớn hơn {more}',
      values: { path: '${path}', more: '${more}' }
    })
  },
  array: {
    default: JSON.stringify({
      defaultMessage: 'Giá trị không hợp lệ',
      values: { path: '${path}' }
    }),
    min: JSON.stringify({
      defaultMessage: 'Trường này phải có ít nhất {min} phần tử',
      values: { path: '${path}', min: '${min}' }
    }),
    max: JSON.stringify({
      defaultMessage: 'Trường này phải có nhiều nhất {max} phần tử',
      values: { path: '${path}', max: '${max}' }
    })
  }
});
