import { Card, CardContent, Grid, Typography } from '@mui/material';

const TotalCard = ({ title, totalAmount, icon, ...rest }) => (
  <Card {...rest}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography sx={{ color: 'white' }} gutterBottom variant="h4">
            {title}
          </Typography>
          <Typography sx={{ color: 'white' }} variant="h3">
            {totalAmount}
          </Typography>
        </Grid>
        <Grid item>{icon}</Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default TotalCard;
