import { useEffect, useState, useCallback } from 'react';
import { Box, Card, Container, Grid, TextField, CardContent, MenuItem, Button } from '@mui/material';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';
import debounce from 'lodash/debounce';

// mui icon
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

import UILoader from '@components/UILoader';
import TotalCard from '@components/dashboard/TotalCard';
import { getDashboardDataAPI } from '@api/main';

const Dashboard = () => {
  const showToast = useToast();
  const [timeStatus, setTimeStatus] = useState('month');
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState([moment().startOf('month').valueOf(), new Date().valueOf()]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);

  const [statistics, setStatistics] = useState({
    clinicCount: '',
    patientCount: '',
    appointmentCount: ''
  });
  const hanleSearch = () => {
    setRefreshToggle(!refreshToggle);
  };
  const handleSearchInputChange = (value) => {
    setDate([moment(value[0]).startOf('day').valueOf(), moment(value[1]).endOf('day').valueOf()]);
  };

  useEffect(() => {
    fetchData(date[0], date[1]);
  }, [refreshToggle]);

  const fetchData = useCallback(
    debounce(async (fromDate, toDate) => {
      try {
        setIsLoading(true);
        const result = await getDashboardDataAPI(fromDate, toDate);
        setStatistics(result.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );

  const handleTimeStatus = (e) => {
    setTimeStatus(e.target.value);

    switch (e.target.value) {
      case 'all':
        setOpen(false);
        setDate([new Date(2022, 0, 1).valueOf(), moment(new Date()).endOf('day').valueOf()]);

        break;
      case 'week':
        setOpen(false);
        setDate([moment().startOf('week').valueOf(), moment(new Date()).endOf('day').valueOf()]);

        break;
      case 'month':
        setOpen(false);
        setDate([moment().startOf('month').valueOf(), moment(new Date()).endOf('day').valueOf()]);

        break;
      case 'year':
        setOpen(false);
        setDate([moment().startOf('year').valueOf(), moment(new Date()).endOf('day').valueOf()]);

        break;
      default:
        return '';
    }
    return '';
  };

  const handleOpenTimePicker = () => {
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Dr.Cloud Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <UILoader open={isLoading} />
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ display: 'flex' }}
                    label="Thời Gian"
                    onChange={handleTimeStatus}
                    value={timeStatus}
                    select
                  >
                    <MenuItem value="week">Tuần này</MenuItem>
                    <MenuItem value="month">Tháng này</MenuItem>
                    <MenuItem value="year">Năm nay</MenuItem>
                    <MenuItem value="all">Toàn Thời Gian</MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleOpenTimePicker();
                      }}
                      value={4}
                    >
                      Tùy Chỉnh
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={4}>
                  <DateRangePicker
                    disabled={!open}
                    startText="Ngày"
                    endText="Ngày"
                    value={date}
                    inputFormat="dd/MM/yyyy"
                    onChange={handleSearchInputChange}
                    renderInput={({ inputProps, ...startProps }, endProps) => {
                      const startValue = inputProps.value;
                      delete inputProps.value;
                      return (
                        <TextField
                          fullWidth
                          {...startProps}
                          inputProps={inputProps}
                          value={`${startValue} - ${endProps.inputProps.value}`}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      py: 0
                    }}
                  >
                    <Button onClick={hanleSearch} sx={{ ml: 1 }} color="primary" variant="contained">
                      Lọc
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Grid sx={{ mt: 0 }} container spacing={3}>
            <Grid item xs={4}>
              <TotalCard
                icon={<BedroomChildIcon fontSize="large" sx={{ color: 'white' }} />}
                title="Phòng Khám Mới"
                totalAmount={statistics.clinicCount}
                sx={{ backgroundImage: 'linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%)', pb: 2 }}
              />
            </Grid>
            <Grid item xs={4}>
              <TotalCard
                icon={<PeopleAltIcon fontSize="large" sx={{ color: 'white' }} />}
                title="Bệnh nhân Mới"
                totalAmount={statistics.patientCount || 0}
                sx={{
                  backgroundImage: 'linear-gradient(to top, rgba(118,255,110,1) 0%, rgba(60,186,146,1) 100%)',
                  pb: 2
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TotalCard
                icon={<MarkEmailUnreadIcon fontSize="large" sx={{ color: 'white' }} />}
                title="Lịch Hẹn Mới"
                totalAmount={statistics.appointmentCount}
                sx={{
                  backgroundImage: 'linear-gradient(to right, rgba(4,190,254,1) 0%, rgba(146,118,249,1) 100%)',
                  pb: 2
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
