import React from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useToast } from '@context/toastContext';
import { getErrorMessage } from '@api/handleApiError';
import { deleteModeratorAPI } from '@api/main';

export default function StaffDeleteModal({ open, user, close }) {
  const showToast = useToast();

  const onSubmit = async () => {
    try {
      await deleteModeratorAPI(user.userId);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Xóa Tài Khoản Thành Công !!'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <Box sx={{ backgroundColor: '#F4F6F8' }}>
        <DialogTitle>
          <Typography sx={{ textAlign: 'center', fontSize: '17px' }}>
            Xóa tài khoản
            {' ' + user.userName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button color="error" onClick={onSubmit} variant="contained">
              Xóa
            </Button>
            <Button color="primary" variant="contained" onClick={close}>
              Đóng
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
